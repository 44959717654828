import React from "react";
import Div from "../Div";
import { communityImg } from "../../images";
import Spacing from "../Spacing";
import "./ec.css";

export default function EmergingCommunity() {
  return (
    <div className="bg-div">
      <Div className="row">
        <Div className="col-lg-6 ">
          <Div>
            <img src={communityImg} alt="Community_Image" className="img-div" />
          </Div>
        </Div>
        <Div className="col-lg-6 col-xl-4">
          <p
            style={{
              fontWeight: "600",
              lineHeight: "1.7",
              fontSize: "1.25rem",
              marginTop: "1rem",
              color:"white",
            }}
          >
            We believe that our community is the heart and soul of our success.
            We foster an inclusive and supportive environment that encourages
            active participation, collaboration, and knowledge-sharing among our
            members. No matter where you come from or what your aspirations may
            be, we invite you to join us in shaping a brighter future and
            experiencing the transformative power of community engagement.
          </p>
          <Spacing lg="45" md="45" />
        </Div>
      </Div>
    </div>
  );
}
