import React from "react";
import BenefitCard from ".";
import Section from "../Div";
import Spacing from "../Spacing";

export default function BenefitCardList() {
  const data = [
    {
      icon: "",
      title: "Blockchain & Web 3",
      description:
        "Blockchain technology revolutionizes trust, offering transparent and secure transactions without the need for intermediaries.",
    },
    {
      icon: "",
      title: "Artificial Intelligence & Machine Learning",
      description:
        "Web 3 brings a paradigm shift by combining decentralized technologies like blockchain, smart contracts, and peer-to-peer networks to create a user-centric and trustless internet experience.",
    },
    {
      icon: "",
      title: "Crypto & NFT",
      description:
        "NFT Tokens are a kind of cryptocurrency that represents a one-of-a-kind digital asset or unique piece of artwork . Fiat and cryptocurrencies are mainly used for transactional purposes and are fungible.",
    },
    {
      icon: "",
      title: "Career Advancement",
      description: "Many industries are embracing blockchain & AI.",
    },
    {
      icon: "",
      title: "Entrepreneurial Opportunities",
      description:
        "Identifying gaps in emerging technologies individuals can seize entrepreneurial opportunities.",
    },
    {
      icon: "",
      title: "Industry Relevance",
      description:
        "Being knowledgeable about emerging technologies allows individuals to discussions and decision-making processes.",
    },
  ];
  return (
    <Section className="position-relative">
      <Section className="row">
        {data.map((d, i) => (
          <Section className="col-lg-4" key={i}>
            <BenefitCard title={d.title} description={d.description} />
            <Spacing lg="25" md="25" />
          </Section>
        ))}
      </Section>
    </Section>
  );
}
