import React from "react";
import Div from "../Div";
import { communityImg } from "../../images";
import Spacing from "../Spacing";
import "./op.css";

export default function OurPhilosophy() {
  const data = [
    {
      title: "Who we are",
      desc: "Our philosophy at Pro Miners is rooted in the belief that knowledge empowers and technology can transform lives for the better. We are driven by a deep passion to make a meaningful impact in the world of cryptocurrency, blockchain, NFTs, and emerging technologies.",
      line: <div class="vertical"></div>,
    },
    {
      title: "Our Philosophy",
      desc: "Our philosophy is built upon the values of inclusivity, collaboration, and continuous learning. We believe that everyone, regardless of their background or expertise, has the potential to contribute and benefit from the advancements in technology.",
      line: <div class="vertical"></div>,
    },
    {
      title: "How we work",
      desc: "We are committed to providing accurate, reliable, and up-to-date information to our community. We understand that the landscape of technology is constantly evolving, and we are dedicated to staying at the forefront of these changes.",
    },
  ];
  const imgData = [
    { imageURL: communityImg, title: "" },
    { imageURL: communityImg, title: "" },
  ];
  return (
    <div>
      <Div className="row">
        <Div className="col-lg-7">
          <Div className="cs-half_screen-">
            <img
              src={communityImg}
              alt="Community_Image"
              className="img-div"
            />
          </Div>
        </Div>
        <Div className="col-lg-5 col-xl-4">
          {imgData.map((d, i) => (
            <Div className="cs-half_screen-" key={i}>
              <img
                src={d.imageURL}
                alt="Community_Image"
                className="img-div-half"
              />
            </Div>
          ))}
          <Spacing lg="45" md="45" />
        </Div>
      </Div>
      <Div className="row" style={{ marginTop: "2.8rem" }}>
        {data.map((d, i) => (
          <>
            <Div className="col" key={i}>
              <h3 className="cs-pricing_title" style={{ height: "2.5rem" }}>
                {d.title}
              </h3>
              <Div className="cs-price_text">{d.desc}</Div>
            </Div>
            {d.line ? <Div className="col-lg-1">{d.line}</Div> : <></>}
          </>
        ))}
      </Div>
    </div>
  );
}
