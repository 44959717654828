import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react';
import Div from '../Div';

export default function SocialWidget() {
  return (
    <Div className="cs-social_btns cs-style1">
      <Link to='https://pin.it/6jccY6h' className="cs-center">
        <Icon icon="fa6-brands:pinterest" />
      </Link>
      <Link to='https://www.snapchat.com/add/prominersin?share_id=6ZCPVtO5g9c&locale=en-US' className="cs-center">
        <Icon icon="fa6-brands:snapchat" />               
      </Link>
      <Link to='https://twitter.com/prominersin?t=LBoLYl-3HKDDkyxRceP6UA&s=09' className="cs-center">
        <Icon icon="fa6-brands:twitter" />               
      </Link>
      <Link to='https://www.youtube.com/@prominersin' className="cs-center">
        <Icon icon="fa6-brands:youtube" />              
      </Link>
      <Link to='https://www.instagram.com/prominersin/' className="cs-center">
        <Icon icon="fa6-brands:instagram" />
      </Link>
    </Div>
  )
}
