import React, { useState } from "react";
import Slider from "react-slick";
import { Icon } from "@iconify/react";
import Testimonial from "../Testimonial";
import Div from "../Div";
import Spacing from "../Spacing";
export default function TestimonialSlider() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const testimonialData = [
    {
      testimonialThumb: "/images/testimonial_1.jpeg",
      testimonialText:
        "Being a part of the Prominers blockchain community has been an incredible journey. The members are passionate, knowledgeable, and always ready to lend a helping hand. It's a supportive and inspiring community that has helped me grow both personally and professionally in the blockchain space.",
      avatarName: "Sarah",
      avatarDesignation: "CEO AT TECH",
      ratings: "4.5",
    },
    {
      testimonialThumb: "/images/testimonial_2.jpeg",
      testimonialText:
        "Prominers is more than just a blockchain community; it's a family. The connections I've made here have been invaluable, and the collective expertise within the community is unmatched. Whether you're a beginner or an expert, Prominers is the place to be if you want to stay at the forefront of blockchain innovation.",
      avatarName: "Mia",
      avatarDesignation: "CTO AT TECH",
      ratings: "5",
    },
    {
      testimonialThumb: "/images/testimonial_3.jpeg",
      testimonialText:
        "Prominers has been a game-changer for me. The community's insights, resources, and discussions have expanded my understanding of blockchain technology and its potential applications. I've had the opportunity to collaborate with like-minded individuals and even find exciting career opportunities. Prominers is a must-join community for anyone interested in blockchain.",
      avatarName: "Kritika",
      avatarDesignation: "CMO AT TECH",
      ratings: "4.75",
    },
    {
      testimonialThumb: "/images/testimonial_1.jpeg",
      testimonialText:
        "Prominers has provided me with a platform to share my ideas and collaborate with brilliant minds in the blockchain space. The community fosters a culture of knowledge-sharing and innovation, making it the perfect environment to learn and grow. I'm grateful to be a part of such a vibrant community.",
      avatarName: "Mark",
      avatarDesignation: "CEO AT TECH",
      ratings: "3.75",
    },
    {
      testimonialThumb: "/images/testimonial_1.jpeg",
      testimonialText:
        "Joining Prominers has been a transformative experience for me. The community is filled with industry experts who are always eager to share their wisdom and provide guidance. The educational resources and events organized by Prominers have been instrumental in my professional development. I highly recommend becoming a member to stay ahead in the fast-paced world of blockchain.",
      avatarName: "Amit",
      avatarDesignation: "CEO AT TECH",
      ratings: "3.75",
    },
  ];
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  return (
    <>
      <Div className="cs-gradient_bg_1 cs-shape_wrap_3 cs-parallax">
        <Spacing lg="130" md="80" />
        <Div className="cs-shape_3 cs-to_up">
          <img src="/images/shape_1.svg" alt="Shape" />
        </Div>
        <Div className="container">
          <Div className="cs-testimonial_slider">
            <Div className="cs-testimonial_slider_left">
              <Slider
                asNavFor={nav1}
                ref={(slider2) => setNav2(slider2)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                centerMode={true}
                centerPadding="0px"
                arrows={false}
              >
                {testimonialData.map((item, index) => (
                  <Div className="slider-nav_item" key={index}>
                    <Div className="cs-rotate_img">
                      <Div className="cs-rotate_img_in">
                        <img src={item.testimonialThumb} alt="Thumb" />
                      </Div>
                    </Div>
                  </Div>
                ))}
              </Slider>
            </Div>
            <Div className="cs-testimonial_slider_right">
              <Slider
                asNavFor={nav2}
                ref={(slider1) => setNav1(slider1)}
                prevArrow={<SlickArrowLeft />}
                nextArrow={<SlickArrowRight />}
                className="cs-arrow_style1"
              >
                {testimonialData.map((item, index) => (
                  <Div key={index}>
                    <Testimonial
                      testimonialText={item.testimonialText}
                      avatarName={item.avatarName}
                      avatarDesignation={item.avatarDesignation}
                      ratings={item.ratings}
                    />
                  </Div>
                ))}
              </Slider>
            </Div>
          </Div>
        </Div>
        <Spacing lg="130" md="80" />
      </Div>
    </>
  );
}
