import React from 'react'
import './colorCard.scss'
import Div from '../Div';

export default function ColorCard({title, description, color}) {
  return (
    <Div className= {`cs-pricing_table cs-style1 ${ color }`} style={{height:"20rem"}}>
      <h2 className="cs-pricing_title"style={{height:"8rem"}}>{title}</h2>
      <Div style={{marginTop:"1rem", color:"white"}}>{description}</Div>
    </Div>
  )
}
