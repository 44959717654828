import React from 'react'
import './benefit.scss'
import Div from '../Div';

export default function BenefitCard({title, description, icon}) {
  return (
    <Div className="cs-pricing_table cs-style1" style={{height:"20rem"}}>
      <h2 className="cs-pricing_title"style={{height:"8rem"}}>{title}</h2>
      <Div className="cs-price_text" style={{marginTop:"1rem"}}>{description}</Div>
    </Div>
  )
}
