import React from "react";
import ColorCard from ".";
import Section from "../Div";
import Spacing from "../Spacing";

const ColorCards = () => {
    const data = [
        {
          icon: "",
          title: "Awareness",
          description:
          "Bridging the knowledge gap and ensure that individuals stay informed about the emerging technology.",
        },
        {
          icon: "",
          title: "UpSkill",
          description:
            "Providing accessible and informative content about emerging technologies.",
        },
        {
          icon: "",
          title: "Networking & Collaboration",
          description:
            "Individuals can expand their professional network, collaborate on projects and gain valuable insights.",
        },
      ];
  return (
    <Section className="position-relative">
    <Section className="row">
      {data.map((d, i) => (
        <Section className="col-lg-4" key={i}>
          <ColorCard title={d.title} description={d.description} color={`cs-new-style${i+1}`}/>
          <Spacing lg="25" md="25" />
        </Section>
      ))}
    </Section>
  </Section>
  )
}

export default ColorCards