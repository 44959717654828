import axios from "axios";

//all post api
export const AllBlogPosts = async (formData) => {
  const config = {
    cors: "no-cors",
    method: "get",
    url: `https://faros.live/wp-json/wp/v2/posts`,
  };
  try {
    const res = await axios(config);
    if (res) {
      return res;
    }
    // return res;
  } catch (error) {
    console.log(error.response.data, "users");
    // return error;
  }
};

//author api
export const Author = async () => {
  const config = {
    cors: "no-cors",
    method: "get",
    url: `https://faros.live/wp-json/wp/v2/users`,
  };
  try {
    const res = await axios(config);
    // console.log(res);
    if (res) {
      return res;
    }
  } catch (error) {
    // console.log(error.response.data, "users");
    // return error;
  }
};

//author api
export const PostImg = async (media) => {
  const config = {
    cors: "no-cors",
    method: "get",
    url: `https://faros.live/wp-json/wp/v2/media/${media}`,
  };
  try {
    const res = await axios(config);
    if (res) {
      return res;
    }
    // return res;
  } catch (error) {
    // console.log(error.response.data, "users");
    // return error;
  }
};

//post Comment api
export const PostComments = async (media) => {
  const config = {
    method: "get",
    url: `https://faros.live/wp-json/wp/v2/comments?posts=${media}`,
  };
  try {
    const res = await axios(config);
    if (res) {
      return res;
    }
    // return res;
  } catch (error) {
    // console.log(error.response.data, "users");
    // return error;
  }
};

//Singal post api
export const SingalPostComment = async (media) => {
  const config = {
    cors: "no-cors",
    method: "get",
    url: `https://faros.live/wp-json/wp/v2/comments?post=${media}`,
  };
  try {
    const res = await axios(config);
    if (res) {
      return res;
    }
    // return res;
  } catch (error) {
    // console.log(error.response.data, "users");
    // return error;
  }
};
//singal post api
export const SinglePost = async (media) => {
  const config = {
    cors: "no-cors",
    method: "get",
    url: `https://faros.live/wp-json/wp/v2/posts/${media}`,
  };
  try {
    const res = await axios(config);
    if (res) {
      return res;
    }
    // return res;
  } catch (error) {
    // console.log(error.response.data, "users");
    // return error;
  }
};
export const Comment = async (data) => {
  const config = {
    cors: "no-cors",
    method: "post",
    url: `https://faros.live/wp-json/wp/v2/comments`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };
  try {
    const res = await axios(config);
    // console.log(res);
    if (res) {
      return res;
    }
    // return res;
  } catch (error) {
    // console.log(error.response.data, "users");
    // return error;
  }
};

export const GetInstaArr = async (fields, Access_Token) => {
  const config = {
    cors: "no-cors",
    method: "get",
    url: `https://graph.instagram.com/me/media?fields=${fields}&access_token=${Access_Token}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios(config);
    return res;
    // return res;
  } catch (error) {
    // console.log(error.response.data, "users");
    // return error;
  }
};
