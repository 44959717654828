import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Div from "../Div";
import Youtube from "../Youtube";
import { Icon } from "@iconify/react";

export default function YoutubeSlider() {
  const MAX_VIDEOS = 100;
  const [videos, setVideos] = useState([]);

  const youtubeRssFeed =
    "https://api.rss2json.com/v1/api.json?rss_url=https://youtube.com/feeds/videos.xml?channel_id=UCNMrtO3eRW-wpQVXsqu1xyg";

  const loadVideos = async () => {
    fetch(youtubeRssFeed, { headers: { Accept: "application/json" } })
      .then((res) => res.json())
      .then((data) => data.items.filter((item) => item.title.length > 0))
      .then((newVideos) => newVideos.slice(0, MAX_VIDEOS))
      .then((videos) => setVideos(videos))
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    loadVideos();
  }, [MAX_VIDEOS]);

  /** Slider Settings **/
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <div style={{ paddingBottom: "1.2rem", cursor: "pointer", width: "8%" }}>
        <Icon
          icon="bi:arrow-left"
          style={{ color: "#FEFF00", fontSize: "1.5rem" }}
        />{" "}
        <span style={{ color: "#FEFF00" }}>Previous</span>
      </div>
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <div style={{ paddingTop: "1.2rem", cursor: "pointer", width: "8%" }}>
        <span style={{ color: "#FEFF00" }}>Next</span>{" "}
        <Icon
          icon="bi:arrow-right"
          style={{ color: "#FEFF00", fontSize: "1.5rem" }}
        />
      </div>
    </div>
  );

  return (
    <Slider
      {...settings}
      className="cs-gap-24"
      prevArrow={<SlickArrowLeft />}
      nextArrow={<SlickArrowRight />}
    >
      {videos.map((item, index) => (
        <Div key={index}>
          <Youtube
            url={item.link}
            src={item.link}
            alt={item.alt}
            date={item.date}
            title={item.title}
            thumbnail={item.thumbnail}
          />
        </Div>
      ))}
    </Slider>
  );
}
