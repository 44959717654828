import React, { useEffect, useState } from "react";
import Hero4 from "../Hero/Hero4";
import { pageTitle } from "../../helper";
import Div from "../Div";
import Spacing from "../Spacing";
import SectionHeading from "../SectionHeading";
import MovingText2 from "../MovingText/MovingText2";
import VideoModal from "../VideoModal";
import Portfolio from "../Portfolio";
import { Icon } from "@iconify/react";
import TimelineSlider from "../Slider/TimelineSlider";
import TestimonialSlider from "../Slider/TestimonialSlider";
import Cta from "../Cta";
import LogoList from "../LogoList";
import PostList from "../Post/PostList";
import { Link } from "react-router-dom";
import BenefitCardList from "../BenefitCard/BenefitCardList";
import SkillsCards from "../BenefitCard/SkillsCards";
import ColorCards from "../ColorCards/ColorCards";
import YoutubeSlider from "../Slider/YoutubeSlider";
import EmergingCommunity from "../EmergingCommunity";
import OurPhilosophy from "../OurPhilosophy";

export default function DigitalAgencyHome() {
  const [itemShow, setItemShow] = useState(6);
  pageTitle("Digital Agency");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const heroSocialLinks = [
    {
      name: "Instagram",
      links: "https://www.instagram.com/prominersin/",
    },
    {
      name: "Twitter",
      links: "/",
    },
  ];
  const funfaceData = [
    {
      title: "Digital products",
      factNumber: "550",
    },
    {
      title: "Global happy clients",
      factNumber: "40K",
    },
    {
      title: "Project completed",
      factNumber: "50k",
    },
    {
      title: "Team members",
      factNumber: "250",
    },
  ];
  const serviceData1 = [
    {
      title: "Learning",
    },
    {
      title: "Awareness",
    },
    {
      title: "Community",
    },
  ];
  const serviceData2 = [
    {
      title: "Blockchain",
    },
    {
      title: "Crypto",
    },
    {
      title: "NFT",
    },
    {
      title: "Web 3",
    },
    {
      title: "AI",
    },
    {
      title: "ML",
    },
  ];
  const portfolioData = [
    {
      title: "Colorful Art Work",
      subtitle: "See Details",
      href: "/portfolio/portfolio-details",
      src: "/images/portfolio_11.jpeg",
      category: "ui_ux_design",
    },
    {
      title: "Colorful Art Work",
      subtitle: "See Details",
      href: "/portfolio/portfolio-details",
      src: "/images/portfolio_12.jpeg",
      category: "logo_design",
    },
    {
      title: "Colorful Art Work",
      subtitle: "See Details",
      href: "/portfolio/portfolio-details",
      src: "/images/portfolio_13.jpeg",
      category: "web_design",
    },
    {
      title: "Colorful Art Work",
      subtitle: "See Details",
      href: "/portfolio/portfolio-details",
      src: "/images/portfolio_14.jpeg",
      category: "mobile_apps",
    },
    {
      title: "Colorful Art Work",
      subtitle: "See Details",
      href: "/portfolio/portfolio-details",
      src: "/images/portfolio_15.jpeg",
      category: "ui_ux_design",
    },
    {
      title: "Colorful Art Work",
      subtitle: "See Details",
      href: "/portfolio/portfolio-details",
      src: "/images/portfolio_16.jpeg",
      category: "web_design",
    },
    {
      title: "Colorful Art Work",
      subtitle: "See Details",
      href: "/portfolio/portfolio-details",
      src: "/images/portfolio_11.jpeg",
      category: "ui_ux_design",
    },
    {
      title: "Colorful Art Work",
      subtitle: "See Details",
      href: "/portfolio/portfolio-details",
      src: "/images/portfolio_12.jpeg",
      category: "logo_design",
    },
    {
      title: "Colorful Art Work",
      subtitle: "See Details",
      href: "/portfolio/portfolio-details",
      src: "/images/portfolio_13.jpeg",
      category: "web_design",
    },
  ];
  return (
    <>
      {/* Start Hero Section */}
      <Hero4
        title="We Are <span>Pro</span>miners <br />Emerging Community"
        subtitle="CREATIVE MIND, CREATIVE WORKS."
        scrollDownId="#service"
        socialLinksHeading="Follow Us"
        heroSocialLinks={heroSocialLinks}
        email="prominersin@gmail.com"
      />
      {/* End Hero Section */}

      {/* Start Funfact Section */}
      <Div style={{ marginTop: "-3.5rem" }} className="container">
        {/* <SkillsCards /> */}
        <ColorCards />
      </Div>
      {/* End Funfact Section */}

      {/* Start Services Section */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="What we provide"
          subtitle="Services"
          variant="cs-style1 text-center"
        />
        <Spacing lg="65" md="45" />
      </Div>
      {/* End Services Section */}

      {/* Start Moving Text Section */}
      <MovingText2 data={serviceData1} />
      <Spacing lg="20" md="10" />
      <MovingText2 reverseDirection data={serviceData2} />
      {/* End Moving Text Section */}

      {/* Start About Section */}
      <Div className="cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Spacing lg="150" md="80" />
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-5 col-xl-4">
              <SectionHeading
                title="Blockchain Community"
                subtitle="About Prominers"
                btnText="Learn More"
                btnLink="https://t.ly/EUP1"
              />
              <Spacing lg="45" md="45" />
            </Div>
            <Div className="col-lg-7 offset-xl-1">
              <Div className="cs-half_screen">
                <VideoModal
                  videoSrc="https://www.youtube.com/watch?v=VcaAVWtP48A"
                  bgUrl="/images/video_bg.jpeg"
                  variant="cs-style1 cs-size1"
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End About Section */}

      {/* Start Benefit Cards */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Education Benefits"
          subtitle="Explore"
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <BenefitCardList />
      </Div>
      {/* End Benefit Cards */}

      {/* Start Projects Section */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Portfolio to explore"
          subtitle="Latest Projects"
          variant="cs-style1 text-center"
        />
        <Spacing lg="20" md="10" />
        <Div className="text-center">
          <Link
            to="https://www.instagram.com/prominersin/"
            className="cs-text_btn"
          >
            View All
            <Icon icon="bi:arrow-right" />
          </Link>
        </Div>
        <Spacing lg="90" md="45" />
        <Div className="row">
          {portfolioData.slice(0, itemShow).map((item, index) => (
            <Div
              className={`${
                index === 0 || index === 3 || index === 4
                  ? "col-lg-8"
                  : "col-lg-4"
              }`}
              key={index}
            >
              <Portfolio
                title={item.title}
                subtitle={item.subtitle}
                href={item.href}
                src={item.src}
                variant="cs-style1 cs-type1"
              />
              <Spacing lg="25" md="25" />
            </Div>
          ))}
        </Div>

        {/* <Div className="text-center">
          {portfolioData.length <= itemShow ? (
            ""
          ) : (
            <>
              <Spacing lg="65" md="40" />
              <span
                className="cs-text_btn"
                onClick={() => setItemShow(itemShow + 3)}
              >
                <span>Load More</span>
                <Icon icon="bi:arrow-right" />
              </span>
            </>
          )}
        </Div> */}
      </Div>
      {/* End Projects Section */}

      {/* Start Youtube Slider */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Our Channel"
          subtitle="Explore"
          variant="cs-style1 text-center"
        />
        <Spacing lg="70" md="45" />
        <YoutubeSlider />
        <Spacing lg="10" md="10" />
        <Div className="text-center">
          <Link
            to="https://www.youtube.com/@prominersin"
            className="cs-text_btn"
          >
            View All
            <Icon icon="bi:arrow-right" />
          </Link>
        </Div>
      </Div>
      {/* End Youtube Slider */}

      {/* Start Emerging Community */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Emerging Community"
          subtitle="Prominers"
          variant="cs-style1 text-center"
        />
        <Spacing lg="80" md="45" />
        <EmergingCommunity />
        <Spacing lg="10" md="10" />
      </Div>
      {/* End Emerging Community */}


      {/* Start Our  philosophy */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Philosophy"
          subtitle="Prominers"
          variant="cs-style1 text-center"
        />
        <Spacing lg="80" md="45" />
        <OurPhilosophy />
        <Spacing lg="10" md="10" />
      </Div>
      {/* End Our philosophy */}

      {/* Start Awards Section */}
      <Spacing lg="140" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-4">
            <SectionHeading
              title="We get multiple awards"
              subtitle="Our Awards"
              variant="cs-style1"
            />
            <Spacing lg="90" md="45" />
          </Div>
          <Div className="col-xl-7 offset-xl-1">
            <TimelineSlider />
          </Div>
        </Div>
      </Div>
      <Spacing lg="145" md="80" />
      {/* End Awards Section */}

      {/* Start Testimonial Section */}
      <TestimonialSlider />
      {/* End Testimonial Section */}

      {/* Start Blog Section */}
      <Div className="cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Spacing lg="145" md="80" />
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-5 col-xl-4">
              <SectionHeading
                title="Explore recent publication"
                subtitle="Our Blog"
                btnText="View More Blog"
                btnLink="https://faros.live/"
              />
              <Spacing lg="45" md="45" />
            </Div>
            <Div className="col-lg-7 offset-xl-1">
              <PostList />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="145" md="80" />
      {/* End Blog Section */}

      {/* Start LogoList Section */}
      <Div className="container">
        <SectionHeading
          title="Our reputed partner"
          subtitle="Top Clients"
          variant="cs-style1 text-center"
        />
        <Spacing lg="70" md="45" />
        <LogoList />
      </Div>
      <Spacing lg="150" md="80" />
      {/* End LogoList Section */}

      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Let’s disscuse make <br />something <i>cool</i> together"
          btnText="Join Now"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
